import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import MailBoxIcon from '@material-ui/icons/MarkunreadMailbox'
import FacebookIcon from '@material-ui/icons/Facebook'

import useStyles from './contact-bar-styles'

export default function ContactBar() {
  var classes = useStyles()
  var theme = useTheme()
  var breakpoints = {
    960: useMediaQuery('min-width:960px'),
  }

  var Link = styled.a`
    color: ${theme.palette.primary.contrastText};
    text-decoration: none;
    &:hover {
      color: ${theme.palette.primary.main};
    }
  `

  return (
    <Toolbar className={`${classes.root} ${classes.contact}`}>
      <div className={classes.content}>
        <div className={classes.container}>
          {/* <Link href="tel:603-479-1589" className={classes.contactLink}>
            <PhoneIcon className={classes.icon} />
            <Typography noWrap>Call us (603)-479-1589</Typography>
          </Link> */}
          <Link
            href="mailto:info@newhampshiredsa.org"
            className={classes.contactLink}
          >
            <EmailIcon className={classes.icon} />
            <Typography noWrap>Email us info@newhampshiredsa.org</Typography>
          </Link>
          <div className={classes.contactLink}>
            <MailBoxIcon className={classes.icon} />
            <Typography noWrap>PO Box 259 Londonderry, NH 03053</Typography>
          </div>
        </div>
        <Link
          aria-label="NHDSA Facebook Page"
          href="https://www.facebook.com/NewHampshireDSA"
        >
          <FacebookIcon className={classes.icon} />
        </Link>
      </div>
    </Toolbar>
  )
}
