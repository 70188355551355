import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contact: {
    backgroundColor: theme.palette.primary.dark,
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  contactLink: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  icon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,

    '&.MuiSvgIcon-root': {
      fill: theme.palette.primary.contrastText,
    },
  },
  content: {
    minWidth: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    height: props => {
      if (props.xsScreen) {
        return '36px'
      }
      return '64px'
    },
    margin: '16px',
  },
  donate: {
    whiteSpace: 'nowrap',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '360px',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

export default useStyles
