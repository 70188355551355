import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contact: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  contactLink: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  icon: {
    margin: theme.spacing(1),
  },
  content: {
    minWidth: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default useStyles
