import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(4),
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
}))

export default useStyles
