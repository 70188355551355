import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import useStyles from './top-nav-styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'

import Logo from 'assets/logo.svg'
import ContactBar from 'components/contact-bar/contact-bar'
import UnderConstruction from 'components/under-construction/under-construction'
import NavLink from 'components/nav-link/nav-link'

var categories = [
  'home',
  'about',
  'resources',
  'grant',
  // 'join',
  'parents',
  // 'events',
  'contact',
]

var slugsQuery = graphql`
  query SlugsQuery {
    gcms {
      pages {
        category
        slug
      }
    }
  }
`

export default function TopNav({}) {
  var [open, setOpen] = React.useState(false)
  var theme = useTheme()
  var smScreen = useMediaQuery(`(max-width: ${theme.breakpoints.values.md}px)`)
  var xsScreen = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`)
  var classes = useStyles({
    xsScreen,
  })
  var data = useStaticQuery(slugsQuery)

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <AppBar position="static">
        <ContactBar />
        <Toolbar className={classes.root}>
          <div className={classes.content}>
            <Link to="/" aria-label="home page">
              <img src={Logo} className={classes.logo} />
            </Link>
            <div className={classes.container}>
              {!smScreen && (
                <ul
                  css={css`
                    list-style: none;
                  `}
                  className={classes.container}
                >
                  {categories.map(category => {
                    let slugs = []

                    data.gcms.pages.forEach(page => {
                      if (page.category === category) {
                        slugs.push(page.slug)
                      }
                    })

                    return (
                      <li>
                        <NavLink category={category} slugs={slugs} />
                      </li>
                    )
                  })}
                </ul>
              )}
              <Fab
                size={xsScreen ? 'small' : 'large'}
                variant="extended"
                color="secondary"
                href="https://give.classy.org/NHDSA"
                className={classes.donate}
              >
                Donate Now
              </Fab>
              {smScreen && (
                <IconButton
                  aria-label="Open Menu"
                  className={classes.icon}
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={open}
        className={classes.drawer}
        onClose={handleClose}
      >
        {/* <ul
          css={css`
            list-style: none;
          `}
          className={classes.container}
        >
          {categories.map(category => {
            let slugs = []

            data.gcms.pages.forEach(page => {
              if (page.category === category) {
                slugs.push(page.slug)
              }
            })

            return (
              <li>
                <NavLink category={category} slugs={slugs} />
              </li>
            )
          })}
        </ul> */}
        <List component="nav">
          {categories.map(category => {
            let slugs = []

            data.gcms.pages.forEach(page => {
              if (page.category === category) {
                slugs.push(page.slug)
              }
            })

            return (
              <NavLink
                category={category}
                slugs={slugs}
                list={true}
                onClose={handleClose}
              />
            )
          })}
        </List>
      </Drawer>
    </>
  )
}
