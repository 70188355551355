import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contact: {
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  contactLink: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  icon: {
    margin: theme.spacing(1),
  },
  content: {
    minWidth: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

export default useStyles
