import React from 'react'
import { Link, navigate } from 'gatsby'
import useStyles from './nav-link-styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MUILink from '@material-ui/core/Link'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'

var categoryTitles = {
  home: 'Home',
  about: 'About',
  resources: 'Resources',
  grant: 'Support Grant',
  join: 'Join NHDSA',
  parents: 'New Parents',
  events: 'Events',
  contact: 'Contact Us',
  board: 'Board of Directors',
}

export default function NavLink({ category, slugs, list = false, onClose }) {
  var [open, setOpen] = React.useState(true)
  var classes = useStyles()
  var firstSlug = `/${slugs[0]}`
  if (category === 'home') {
    firstSlug = '/'
  }

  function handleNested() {
    setOpen(!open)
  }

  if (list) {
    if (slugs.length > 1) {
      return (
        <>
          <ListItem button onClick={handleNested}>
            <ListItemText className={classes.text}>
              {categoryTitles[category]}
            </ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {slugs.map(slug => (
                <ListItem
                  button
                  onClick={() => {
                    onClose()
                    navigate(`/${slug}`)
                  }}
                >
                  <ListItemText className={classes.text}>
                    {categoryTitles[category]}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )
    }

    return (
      <ListItem
        button
        onClick={() => {
          onClose()
          navigate(firstSlug)
        }}
      >
        <ListItemText className={classes.text}>
          {categoryTitles[category]}
        </ListItemText>
      </ListItem>
    )
  }

  return (
    <Link to={firstSlug} className={classes.root}>
      <MUILink color="inherit" variant="button">
        {categoryTitles[category]}
      </MUILink>
    </Link>
  )
}
