import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@material-ui/core/styles'
import ReactDOM from 'react-dom'

import SEO from 'components/SEO'
import TopNav from 'components/top-nav/top-nav'

import './layout.css'

export default function Layout({ children, title }) {
  var theme = useTheme()
  if (process.env.NODE_ENV !== 'production') {
    import('react-axe').then(axe => {
      axe.default(React, ReactDOM, 1000)
    })
  }

  var Main = styled.main`
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: none;
    }
  `

  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <SEO title={title} />
      <TopNav />
      <Main>{children}</Main>
    </>
  )
}
